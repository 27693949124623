import React from "react"

const SmallHero = ({ title, color }) => {
  return (
    <section className={`hero is-bold ${color}`}>
      <div className="hero-body">
        <div className="container">
          <h1 className="title">{title}</h1>
        </div>
      </div>
    </section>
  )
}

export default SmallHero
