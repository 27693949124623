import React from "react"

export default function contact_card() {
  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">Information de contact</p>
      </header>
      <div className="card-content">
        <div className="content">
          Mail :
          <a href="mailto:president@team-kangourou.ch">
            president@team-kangourou.ch
          </a>
          <br />
          Whatsapp : <a href="tel:+41795089281">+41 79 508 92 81</a>
        </div>
      </div>
      <footer className="card-footer">
        <a
          href="https://www.instagram.com/team.kangourou/"
          className="card-footer-item"
        >
          Instagram
        </a>
      </footer>
    </div>
  )
}
