import React from "react"
import Layout from "../components/layout/layout"
import Contact from "../components/contact_card"
import SmallHero from "../components/layout/smallHero"
import "./mystyles.scss"
import { Helmet } from "react-helmet"

export default function register() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inscription</title>
      </Helmet>
      <SmallHero title="Inscription" color="is-success" />
      <section className="section">
        <div className="container">
          <article className="message is-danger">
            <div className="message-body">
              Les Inscriptions en ligne ne sont pas encore disponibles.
              Cependant si vous souhaitez rejoindre la Team Kangourou, vous
              pouvez nous contacter sur les réseaux sociaux ou par message sur
              whatsapp.
            </div>
          </article>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div class="columns is-centered is-vcentered">
            <div className="column is-one-third ">
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
